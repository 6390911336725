const useDashboardDriver = () => {
    return {
        elements : [
            { element: '#navigation', title: 'Welcome to new navigation menu', description: 'We have added navigation on top to give you more space while working on cms..' },
            { element: 'ul .menu-item:nth-child(1)', title: 'Normal Menu Item', description: 'if you click on this you will be taken to the desired page, if you observe we don\'t have an down arrow, which states that it\'s a normal navigation item' },
            { element: 'ul .menu-item:nth-child(2)', title: 'Menu Item With Dropdown', description: 'if you click on this this will open a Mega menu with options inside it, if you can observe we have an down arrow, so it\'s a Mega menu navigation item' },
            { element: '#email_center', title: 'Email Center QuickLink', description: 'This will take you directly to the Email Center page without needing to click on navigation to find it..' },
            { element: '#popups', title: 'Popups Quick Link', description: 'This will take you directly to the Popups page without needing to click on navigation to find it..' },
        ],
    }
}

export default useDashboardDriver;